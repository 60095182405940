import { Fragment, lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import fetchData from './axiosRequest';

import MavryckLoader from './components/MavryckLoader/MavryckLoader';
import { generalLoaderActions } from './store';
import Chatbot from './components/VirtualAssistant/Chatbot';
import Chat from './components/Chat';

const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Signup = lazy(() => import("./pages/Signup"));
const ScenarioPlanning = lazy(() => import("./pages/ScenarioPlanning"));
const SingleScenario = lazy(() => import("./pages/SingleScenario"));
const TimeManager = lazy(() => import("./pages/TimeManager"));
const VisionAI = lazy(() => import("./pages/VisionAI"));
const AppScreen = lazy(() => import("./pages/AppScreen"));
const Vivclima = lazy(() => import("./pages/Vivclima"));
const CostManager = lazy(() => import("./pages/CostManager"));
const ProjectManager = lazy(() => import("./pages/ProjectManager"));
const EstimationApp = lazy(() => import("./pages/EstimationApp"));
const ResourceOptimizer = lazy(() => import("./pages/ResourceOptimizer"));
const RiskManagement = lazy(() => import("./pages/RiskManagement"));
const Gulermak = lazy(() => import("./pages/Gulermak"));

function App() {
  // console.log("build-15-09-2023");
  const virtualAssistant = useSelector(state => state.virtualAssistant.visible);
  const chatVisible = useSelector(state => state.chat.visible);
  const loginHandler = () => {
    // // Example data to be sent in the POST request body (replace with your data)
    // const postData = {
    //   email: 'jpasay@mavryck.com',
    //   password: 'Hello123'
    // };

    // // URL for the POST request (replace with your actual endpoint)
    // const url = 'https://dev.mavryck.com/service/PMIntelCore/rest/login/credentailsCheck';

    // // Options for the POST request
    // const requestOptions = {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json', // Set the Content-Type to JSON if sending JSON data
    //     'Accept': 'application/json',
    //     'Access-Control-Allow-Origin': '*'
    //   },
    //   body: JSON.stringify(postData) // Convert the data to a JSON string
    // };

    // // Sending the POST request
    // fetch(url, requestOptions)
    //   .then(response => {
    //     if (!response.ok) {
    //       throw new Error('Network response was not ok');
    //     }
    //     return response.json(); // Parse the response body as JSON
    //   })
    //   .then(data => {
    //     // Handle the data returned from the server
    //     console.log('Response data:', data);
    //   })
    //   .catch(error => {
    //     // Handle any errors that occurred during the fetch or processing of the response
    //     console.error('Error:', error.message);
    //   });

    fetchData("login/credentailsCheck", "POST", { email: "jpasay@mavryck.com", password: "Hello123" })
      .then((data) => {
        console.log(data.headers)
      })
      .catch((err) => { console.log(err) })
  }

  return (
    <Fragment>
      <BrowserRouter basename='/'>
        <Suspense fallback={<div className='main-loader'><img src={`${process.env.PUBLIC_URL}/images/logo.png`} alt="Mavryck" /></div>}>
          <Routes>
            <Route path="/" exact element={<Home loginHandler={loginHandler} />} />
            <Route path="/Login" exact element={<Login />} />
            <Route path="/ForgotPassword" exact element={<ForgotPassword />} />
            <Route path="/Signup" exact element={<Signup />} />
            <Route path="/scenarioplanning" exact element={<ScenarioPlanning />} />
            <Route path="/activities" exact element={<SingleScenario />} />
            <Route path="/timemanager" exact element={<TimeManager />} />
            <Route path="/saif" exact element={<VisionAI />} />
            <Route path="/AppScreen" exact element={<AppScreen />} />
            <Route path="/Vivclima" exact element={<Vivclima />} />
            <Route path="/FinancialManager" exact element={<CostManager />} />
            <Route path="/ProjectManager" exact element={<ProjectManager />} />
            <Route path="/estimation" exact element={<EstimationApp />} />
            <Route path="/ResourceOptimizer" exact element={<ResourceOptimizer />} />
            <Route path="/riskmanager" exact element={<RiskManagement />} />RiskManagement
            {/* <Route path="/Gulermak" exact element={<Gulermak />} /> */}
            <Route path="*" element={<div>Not Found</div>} />
            <Route path="*" element={<div>Not Found go back to <Link to={`/`}>Home</Link></div>} />
          </Routes>
        </Suspense>
      </BrowserRouter>
      {
        virtualAssistant ?
          <Chatbot />
          : null
      }
      {
        chatVisible ?
          <Chat />
          : null
      }
    </Fragment>
  );
}


function Home(props) {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.generalLoader.loaderVisible);

  useEffect(() => {
    dispatch(generalLoaderActions.showLoader(false))
    // console.log("isLoading", isLoading)
  }, [])
  return (
    <Fragment>
      {/* Loader */}
      {isLoading ? <MavryckLoader /> : ""}
      {/* Loader */}
      {/* <div className='flex flex-wrap flex-col justify-center pt-8'>
        <p className="text-center py-10">Redirect to 
        <b><Link className=" text-violet-600" to={`/scenarioplanning`}>Scenario Planning </Link></b> or 
        <b><Link className=" text-violet-600" to={`/timemanager`}> Time manager </Link></b> or 
        <b><Link className=" text-violet-600" to={`/visionai`}> Vision AI </Link></b> or 
        <b><Link className=" text-violet-600" to={`/Login`}> Login screens</Link></b> or 
        <b><Link className=" text-violet-600" to={`/AppScreen`}> App Screen </Link></b>
        or <b><Link className=" text-violet-600" to={`/Vivclima`}> Vivclima </Link></b> 
        or <b><Link className=" text-violet-600" to={`/ProjectManager`}> Project Manager </Link></b> </p>
      </div> */}
      <div className='flex flex-wrap flex-col justify-center pt-8'>
        <Login />
      </div>
    </Fragment>
  )
}


export default App;
