import axios from "axios"

const axiosRequest = (
  query,
  method = "GET",
  data = undefined,
  headers = undefined
) => {
  // Request URL
  const url = `https://dev.mavryck.com/service/PMIntelCore/rest/${query}`

  // Request Headers
  const reqHeaders = {
    "Access-Control-Allow-Origin": "*",
    crossDomain: true,
    withCredentials: true,
    method,
    url,
  }

  // If POST
  if (method === "POST" && data) {
    reqHeaders.data = data
  }

  // If PUT
  if (method === "PUT" && data) {
    reqHeaders.data = data
  }

  // If PUT
  if (method === "DELETE" && data) {
    reqHeaders.data = data
  }

  // If Custom Header Included
  if (headers) {
    reqHeaders.headers = {
      ...reqHeaders.headers,
      ...headers,
    }
  }

  return axios(reqHeaders)
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        console.error('Server Error:', error.response.status);
        return error.response;
      } else if (error.request) {
        console.error('Network Error:', error.request);
        return error.request;
      } else {
        console.error('Error:', error.message);
        return error.message;
      }
    })
}

export default axiosRequest
